import * as React from 'react';
import Layout from '../../components/layout';
import { Header } from '../../components/organisms/Header';
import DataboutTools from '../../components/organisms/DataboutTools';
import NeedMore from '../../components/organisms/NeedMore';
import transportImage from '../../images/transport.svg';
import { graphql } from 'gatsby';
import NewestNews from '../../components/organisms/NewestNews';
import SubpageAbout from '../../components/organisms/SubpageAbout';
import innertext from 'innertext';
import PageContent from '../../components/organisms/PageContent';
import SEO from '../../components/seo';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getPostModel, WpPost } from '../../shared/wp-post-utils';
import { defaultLanguage } from '../../../site-config';

export type AboutType = {
	data: {
		parent: WpPost,
		children: {
			nodes: WpPost[];
		},
		newestNews: { 
			nodes: WpPost[];
		}
	}	
	location?: any;
}


const Transport: React.FC<AboutType> = ({data, location}) => {
	const {t} = useTranslation();
	const {language} = useI18next();
	const LDAPHeaderUser = JSON.parse(data.parent.author.node.ldapUser)[0];

    return ( 
        <Layout>
			<SEO title={data.parent.title}  description={innertext(data.parent.content)}/>
            <Header 
                title={data.parent.title} 
                description={innertext(data.parent.content)}
                headerImage={transportImage}
                headerImageWidth={600}
				isSignature={true}
                user={data.parent.author.node}
                signatureAuthor={LDAPHeaderUser.name}
                signaturePosition={LDAPHeaderUser.title}
                signatureDeskPhoneNumber={LDAPHeaderUser.telephoneNumber?.[0]}
                signaturePhoneNumber={LDAPHeaderUser.mobile?.[0]}
                signatureEmail={LDAPHeaderUser.mail}
                signatureCity={LDAPHeaderUser.physicalDeliveryOfficeName}
				signatureShareLink={location.href}
                isArrowNavVisible={true}
                firstHeaderNavLink="uslugi"
                firstHeaderNavLinkName={t('services.title')}
				SEOTitle={data.parent.title} 
                SEODescription={innertext(data.parent.content)}
            />
            <PageContent>
                <SubpageAbout data={data.children.nodes} paddingBottom={90}/>
				{data.newestNews.nodes.length > 0 && language === defaultLanguage && <NewestNews posts={data.newestNews.nodes.map(getPostModel)}/>}
                <DataboutTools title="Databout.Tools" subTitle={t('toolsAd.subTitle')} description={t('toolsAd.description')} link="/tools"/>
            <NeedMore/>
            </PageContent>
        </Layout>

     );
}
 
export default Transport;

export const pageQuery = graphql`
query($language: String!) {
	parent: wpPage(title: {eq: "Transport"}, language: {slug: {eq: $language}}) {
		title
		content
		...AuthorPage
	}
	children: allWpPage(filter: {wpParent: {node: {slug: {regex: "/transport/"}}}, language: {slug: {eq: $language}}}, sort: {fields: menuOrder}) {
		nodes {
			title
			content
			...AuthorPage
		}
	}
	newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}, tags: {nodes: {elemMatch: {name: {eq: "transport"}}}}}
    limit: 3
	) {
		nodes {
			...PageItems
		}
	}
	locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`
 



